import React, { FC } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0 0 25px;
    width: 235px;
    text-align: right;
  }

  @media (max-width: 1024px) {
    width: 100%;
    h2 {
      text-align: left;
      width: unset;
    }
  }
`;

const StyledInnerWrapper = styled.div`
  div {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    h3 {
      font-size: 1.4rem;
      font-weight: 400;
      margin: 0 40px 0 0;
      width: 235px;
      text-align: right;
    }

    p {
      font-size: 1.4rem;
      font-weight: 700;
      margin: 0;
      width: 185px;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-height: 940px) {
    div {
      h3 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.8rem;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    div {
      width: 100%;
      justify-content: space-between;
      h3 {
        text-align: left;
        width: unset;
        margin-right: 20px;
      }

      p {
        text-align: right;
        width: unset;
      }
    }
  }
`;

const Table: FC<{
  name: string;
  items: { name: string; value: string }[];
  className?: string;
}> = ({ name, items, className }) => {
  return (
    <StyledWrapper className={className}>
      <h2>{name}</h2>

      <StyledInnerWrapper>
        {items.map(({ name, value }, index) => (
          <div key={index}>
            <h3 dangerouslySetInnerHTML={{ __html: name }} />
            <p dangerouslySetInnerHTML={{ __html: value }} />
          </div>
        ))}
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default Table;
