import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import BasicTemplate from '../templates/BasicTemplate';
import Table from '../components/atoms/Table/Table';
import Logo from '../components/atoms/Logo/Logo';
import Icon from '@iconify/react';
import { arrowInCircleIcon, arrowLeftIcon } from '../assets/icons';
import { graphql, Link } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import Image from '../components/atoms/Image/Image';
import Button from '../components/atoms/Button/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { HOUSES } from '../config/houses';
import { formatNumber } from '../utils/formatNumber';
import { filterRooms } from '../utils/filterRooms';
import { Availability } from '../types/availability';
import { getColor } from '../utils/getColor';
import { HouseInformation } from '../components/molecules/InteractiveVisualizationSection/types/houseInformation';

SwiperCore.use([Navigation]);

const StyledWrapper = styled.div`
  display: flex;
  height: 100vh;
  min-height: 720px;
  overflow: hidden;

  > div:first-of-type {
    width: 30%;
    max-width: 582px;
    background: #eee;
    height: 100%;
    padding: 50px 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 450px;
  }

  > div:last-of-type {
    flex: 1;
    padding: 20px 80px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  @media (max-width: 1350px) {
    > div:last-of-type {
      padding: 20px 40px 40px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    height: unset;
    padding-top: 120px;
    position: relative;
    overflow: visible;
    padding-bottom: 90px;

    > div:first-of-type {
      width: 90%;
      min-width: unset;
      background: #fff;
      margin: auto;
      padding: 0;
      align-items: center;

      > div:first-of-type {
        margin-top: 60px;
      }

      > div:nth-of-type(3) {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
`;

const StyledLeftColumnHeader = styled.div<{ $availability: Availability }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 3.6rem;
    margin: 0;
  }

  p {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;

    span {
      color: ${({ $availability }) => getColor($availability)};
      display: block;
    }

    ${({ $availability }) =>
      $availability === Availability.RESERVED &&
      css`
        @media (max-width: 1700px) {
          span {
            font-size: 1.6rem;
          }
          font-size: 1.8rem;
      `}
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    h1 {
      margin: 0 0 15px;
    }

    span {
      display: inline !important;
    }
  }

  @media (max-width: 420px) {
    h1 {
      font-size: 2.4rem;
    }

    p {
      font-size: 1.8rem;
    }
  }
`;

const StyledTable = styled(Table)<{ $first?: boolean }>`
  margin-top: 50px;

  :last-of-type {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    max-height: 600px;

    h2 {
      width: unset;
      text-align: left;
    }

    > div {
      overflow: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        background: #ddd;
      }

      ::-webkit-scrollbar-thumb {
        background: #b4b4b4;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #959595;
      }

      ::-webkit-scrollbar-thumb:active {
        background: #848484;
      }
    }
  }

  ${({ $first }) =>
    $first &&
    css`
      //> div > div:nth-of-type(4) {
      //  @media (max-width: 1864px) {
      //    justify-content: center;
      //    h3 {
      //      width: 150px;
      //    }
      //
      //    p {
      //      width: 140px;
      //      font-size: 1.2rem;
      //    }
      //  }
      //}
    `};

  @media (max-width: 1024px) {
    :last-of-type {
      max-height: unset;
      > div {
        overflow: visible;
      }
    }
  }

  @media (max-width: 420px) {
    margin-top: 30px;

    :last-of-type {
      margin-top: 40px;
    }
  }
`;

const StyledRightColumnHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  img {
    width: 135px;
    height: auto;
    margin-right: auto;
  }

  h2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -75%);
    font-size: 2.4rem;
    margin: 0 auto 0 0;
  }

  @media (max-width: 1400px) {
    h2 {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    order: 2;
    justify-content: center;
    margin-top: 40px;
    a {
      position: fixed;
      top: 0;
      left: 5%;
      height: 60px;
      width: 100px;
      z-index: 999999999;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 70px;
    }

    h2 {
      display: block;
      position: static;
      transform: unset;
      text-align: center;
      margin: 0 0 25px;
    }
  }

  @media (max-width: 390px) {
    a {
      height: 50px;
      width: 80px;
    }
    img {
      width: 50px;
    }
  }
`;

const StyledGalleryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  button {
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 8.4rem;
    transition: color 0.2s ease-in-out;

    :first-of-type {
      svg {
        transform: rotate(180deg) !important;
      }
    }

    :hover {
      color: #737373;
    }

    :active svg {
      transform: scale(0.9) !important;
    }

    :first-of-type:active {
      svg {
        transform: rotate(180deg) scale(0.9) !important;
      }
    }
  }

  @media (max-width: 1024px) {
    order: 1;
    max-width: 582px;
    margin: auto;
    width: 100%;
    button {
      font-size: 6rem;
    }
  }

  @media (max-width: 1350px) {
    button {
      font-size: 5rem;
    }
  }

  @media (max-width: 440px) {
    button {
      font-size: 4rem;
    }
  }
`;

const StyledGalleryImage = styled(Image)`
  width: 100%;
  height: 27.6875vw;
  max-width: 850px;
  max-height: 570px;

  img,
  picture,
  source {
    object-fit: contain !important;
  }

  @media (max-width: 720px) {
    width: 65vw;
    height: 42vw;
  }

  @media (max-width: 440px) {
    width: 100%;
  }
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    list-style-type: none;
    padding: 0;
  }

  @media (min-width: 1025px) and (max-width: 1350px) {
    > a:last-of-type {
      position: absolute;
      top: 140px;
      right: 75px;
    }
  }

  @media (max-width: 1024px) {
    order: 3;
    flex-direction: column;

    > a:last-of-type {
      position: absolute;
      bottom: 20px;
    }
  }
`;

const StyledVisTypeButton = styled.button<{ $active: boolean }>`
  font-size: 1.8rem;
  font-weight: 700;
  color: #ccc;
  margin-left: 40px;
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  :first-of-type {
    margin-left: 0;
  }

  :hover {
    color: #737373;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: #000;
      text-decoration: underline;
      cursor: default;

      :hover {
        color: #000;
      }
    `};

  @media (max-width: 420px) {
    font-size: 1.6rem;
  }
`;

const StyledBackButton = styled(Link)`
  border: 0;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  :hover {
    color: #737373;
  }

  svg {
    margin-right: 15px;
  }

  @media (max-width: 1024px) {
    position: absolute;
    left: 10%;
    top: 100px;
  }
`;

const StyledSwiperWrapper = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
  max-width: 850px;
  position: relative;

  .swiper-container {
    max-width: 100%;
    overflow: hidden;
  }

  .swiper-wrapper {
    display: inline-flex;
  }

  .swiper-slide {
    width: 100%;
  }

  @media (max-width: 720px) {
    flex: 1;
  }
`;

const getFullArea = (rooms: { name: string; area: number }[]) => {
  let sum = 0;

  rooms.forEach(({ area, name }) => {
    if (name === 'Garaż' || name === 'Kotłownia') {
      return;
    }

    sum += area;
  });

  return formatNumber(sum);
};

interface Data {
  datoCmsHouse: HouseInformation;
  firstFloor: { childImageSharp: ImageDataLike };
  firstFloor2d: { publicURL: string };
  secondFloor: { childImageSharp: ImageDataLike };
  secondFloor2d: { publicURL: string };
}

const Gallery: FC<{
  currentFloor: 0 | 1;
  setCurrentFloor: React.Dispatch<React.SetStateAction<0 | 1>>;
  setVisType: React.Dispatch<React.SetStateAction<0 | 1>>;
  visType: 0 | 1;
  data: Data;
  house: HouseInformation;
  className?: string;
}> = ({
  currentFloor,
  setCurrentFloor,
  visType,
  data,
  setVisType,
  house,
  className,
}) => {
  return (
    <div className={className}>
      <StyledRightColumnHeader>
        <Link to="/" aria-label="Strona główna">
          <Logo />
        </Link>
        <h2>{currentFloor === 0 ? 'Parter' : 'Piętro'}</h2>
      </StyledRightColumnHeader>

      <StyledGalleryWrapper>
        <button aria-label="Poprzednie zdjęcie" id="prev-button">
          <Icon icon={arrowInCircleIcon} />
        </button>

        <StyledSwiperWrapper>
          <Swiper
            grabCursor
            updateOnWindowResize
            slidesPerView={1}
            spaceBetween={0}
            navigation={{
              nextEl: `#next-button`,
              prevEl: `#prev-button`,
            }}
            onSlideChange={(e) => {
              setCurrentFloor(e.realIndex as 0 | 1);
            }}
            loop
          >
            <SwiperSlide>
              <StyledGalleryImage
                alt="Nowa Farma"
                image={
                  visType === 0
                    ? getImage(data.firstFloor.childImageSharp)
                    : data.firstFloor2d.publicURL
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <StyledGalleryImage
                alt="Nowa Farma"
                image={
                  visType === 0
                    ? getImage(data.secondFloor.childImageSharp)
                    : data.secondFloor2d.publicURL
                }
              />
            </SwiperSlide>
          </Swiper>
        </StyledSwiperWrapper>

        <button aria-label="Następne zdjęcie" id="next-button">
          <Icon icon={arrowInCircleIcon} />
        </button>
      </StyledGalleryWrapper>

      <StyledBottomWrapper>
        <StyledBackButton to="/" state={{ scrollTo: '#houses' }}>
          <Icon icon={arrowLeftIcon} />
          Powrót
        </StyledBackButton>

        <div>
          <StyledVisTypeButton
            $active={visType === 0}
            onClick={() => setVisType(0)}
          >
            Wizualizacja
          </StyledVisTypeButton>
          <StyledVisTypeButton
            $active={visType === 1}
            onClick={() => setVisType(1)}
          >
            Rzut 2D
          </StyledVisTypeButton>
        </div>

        <Button as="a" target="_blank" href={`/karta_${house.number}.pdf`}>
          Pobierz kartę PDF
        </Button>
      </StyledBottomWrapper>
    </div>
  );
};

const StyledGallery = styled(Gallery)<{
  $first?: boolean;
  $second?: boolean;
}>`
  ${(props) =>
    props.$first &&
    css`
      @media (max-width: 1024px) {
        display: none !important;
      }
    `}
  ${(props) =>
    props.$second &&
    css`
      display: none !important;
      @media (max-width: 1024px) {
        display: flex !important;
      }
    `}
`;

const House: FC<{
  data: Data;
}> = ({ data }) => {
  const [visType, setVisType] = useState<0 | 1>(0);
  const [currentFloor, setCurrentFloor] = useState<0 | 1>(0);

  const house = data.datoCmsHouse;

  return (
    <BasicTemplate
      isAnotherThanIndex
      title={`Lokal ${house.number.toUpperCase()}`}
    >
      <StyledWrapper>
        <div>
          <StyledLeftColumnHeader $availability={house.availability}>
            <h1>Lokal {house.number}</h1>
            <p>
              Status: <span>{house.availability}</span>
            </p>
          </StyledLeftColumnHeader>

          <StyledTable
            name="Parametry domu:"
            $first
            items={[
              {
                name: 'Powierzchnia mieszkalna:',
                value: `${formatNumber(house.area)} m<sup>2</sup`,
              },
              {
                name: 'Powierzchnia działki:',
                value: `${formatNumber(house.parcelArea)} m<sup>2</sup`,
              },
              {
                name: 'Całkowita ilość pomieszczeń:',
                value: house.roomsCount.toString(),
              },
              {
                name: 'Garaż:',
                value: house.garage,
              },
              { name: 'Termin oddania:', value: 'III kwartał 2022' },
            ]}
          />

          <StyledGallery
            currentFloor={currentFloor}
            setCurrentFloor={setCurrentFloor}
            setVisType={setVisType}
            visType={visType}
            data={data}
            house={house}
            $second
          />

          <StyledTable
            name={`Wykaz pomieszczeń (${
              currentFloor === 0 ? 'Parter' : 'Piętro'
            }):`}
            items={[
              ...filterRooms(
                currentFloor === 0 ? house.firstFloor : house.secondFloor
              ),
              {
                name: '<b>P. uż. mieszkalna razem</b>',
                value: `${getFullArea(
                  currentFloor === 0 ? house.firstFloor : house.secondFloor
                )} m<sup>2</sup>`,
              },
            ]}
          />
        </div>
        <StyledGallery
          currentFloor={currentFloor}
          setCurrentFloor={setCurrentFloor}
          setVisType={setVisType}
          visType={visType}
          data={data}
          house={house}
          $first
        />
      </StyledWrapper>
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($houseNumber: String!) {
    datoCmsHouse(number: { eq: $houseNumber }) {
      number
      area
      parcelArea
      availability
      deliveryDate
      garage
      roomsCount
      firstFloor {
        name
        area
      }
      secondFloor {
        name
        area
      }
    }

    firstFloor: file(name: { eq: "first_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
    secondFloor: file(name: { eq: "second_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
    firstFloor2d: file(name: { eq: "first_svg_floor" }) {
      publicURL
    }
    secondFloor2d: file(name: { eq: "second_svg_floor" }) {
      publicURL
    }
  }
`;

export default House;
