import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Image: FC<{ image: any; alt: string; className?: string }> = ({
  image,
  alt,
  className,
}) => {
  if (typeof image === 'string') {
    return <img src={image} alt={alt} className={className} />;
  }

  return <GatsbyImage alt={alt} image={image} className={className} />;
};

export default Image;
